import React from "react";
import "./Navbar.css";

const Navbar = ({ openModal }) => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-brand">KAROLINA LANGE</div>
        <ul className="navbar-menu">
          {/* <li>
            <a href="/">Home</a>
          </li> */}
          <li>
            <button onClick={openModal} className="nav-button">
              Dokumenty
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
