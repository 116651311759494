import React, { useState, useEffect } from "react";
import "./Card.css";

const Card = ({ isOpen, toggleModal, language = "pl" }) => {

  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const languageCycle = ["pl", "en", "uk"]; // Cycle through the languages
    let index = languageCycle.indexOf(currentLanguage);

    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        index = (index + 1) % languageCycle.length;
        setCurrentLanguage(languageCycle[index]);
        setIsAnimating(false);
      }, 600); // Match timeout with transition duration
    }, 3000); // Change language every 3 seconds

    return () => clearInterval(interval);
  }, [currentLanguage]);

  const sections = [
    {
      title: {
        pl: "Polityka Prywatności - Kandydaci",
        en: "Privacy Policy - Candidates",
        uk: "Політика конфіденційності - Кандидати",
      },
      files: [
        {
          name: "Polski",
          url: "/documents/DLA-KANDYDATOW_POLITYKA-PRYWATNOSCI.pdf",
        },
        {
          name: "English",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-KANDYDATA_ANGIELSKI.pdf",
        },
        {
          name: "Українська",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-KANDYDATA_UKRAINSKI.pdf",
        },
      ],
    },
    {
      title: {
        pl: "Polityka Prywatności - Pracownicy",
        en: "Privacy Policy - Employees",
        uk: "Політика конфіденційності - Працівники",
      },
      files: [
        {
          name: "Polska (Aktualizacja)",
          url: "/documents/DLA-PRACOWNIKOW_POLITYKA-PRYWATNOSCI_AKTUALIZACJA.pdf",
        },
        {
          name: "English",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-PRACOWNIKA_ANGIELSKI.pdf",
        },
        {
          name: "Українська",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-PRACOWNIKA_UKRAINSKI.pdf",
        },
        {
          name: "Українська 1",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-PRACOWNIKA_UKRAINSKI-1.pdf",
        },
        {
          name: "Українська 2",
          url: "/documents/POLITYKA-PRYWATNOSCI-DLA-PRACOWNIKA_UKRAINSKI-2.pdf",
        },
      ],
    },
    {
      title: {
        pl: "Klauzula Informacyjna",
        en: "Information Clause",
        uk: "Інформаційне положення",
      },
      files: [
        {
          name: "Dla Dostawców i Kontrahentów",
          url: "/documents/KLAUZULA-INFORMACYJNA-DLA-DOSTAWCOW-I-KONTRAHENTOW_DO-DRUKU.pdf",
        },
        {
          name: "W Procesie Reklamacyjnym",
          url: "/documents/KLAUZULA-INFORMACYJNA-W-PROCESIE-REKLAMACYJNYM-I-LIKWIDACJI-SZKOD_DO-DRUKU.pdf",
        },
      ],
    },
    {
      title: {
        pl: "Informacja o Monitoringu",
        en: "Monitoring Information",
        uk: "Інформація про моніторинг",
      },
      files: [
        {
          name: "Do Druku",
          url: "/documents/INFORMACJA-O-MONITORINGU_DO-DRUKU.pdf",
        },
        {
          name: "Do Druku 1",
          url: "/documents/INFORMACJA-O-MONITORINGU_DO-DRUKU-1.pdf",
        },
        {
          name: "McDrive - Do Druku",
          url: "/documents/INFORMACJA-O-MONITORINGU-MCDRIVE_DO-DRUKU.pdf",
        },
        {
          name: "McDrive - Do Druku 1",
          url: "/documents/INFORMACJA-O-MONITORINGU-MCDRIVE_DO-DRUKU-1.pdf",
        },
      ],
    },
  ];

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Dostępne do pobrania</h2>
        <p className="subtitle">Stuknij wybrany dokument i pobierz.</p>
        {sections.map((section, index) => (
          <div key={index} className="section">
            <h3
              className={`animated-title ${isAnimating ? 'exiting' : 'entering'}`}
            >
              {section.title[currentLanguage]}
            </h3>
            <ul>
              {section.files.map((file, fileIndex) => (
                <li key={fileIndex}>
                  <a href={file.url} download>
                    {file.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <button onClick={toggleModal} className="close-button">
          zamknij.
        </button>
      </div>
    </div>
  );
};

export default Card;
