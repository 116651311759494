import "./App.css";
import FluidGradientBackground from "./components/FluidGradientBackground";
import Card from "./components/Card";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import InfoSection from "./components/InfoSection";
import { useState } from "react";
import HeroArea from "./components/HeroArea";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div className="App">
      <FluidGradientBackground />
      <Navbar openModal={toggleModal} />
      <main className="content">
        {/* <GeneralInfoSection /> */}
        <HeroArea />
        <section>
          <h1>Nasze restauracje.</h1>

          <InfoSection />
        </section>
      </main>
      <Footer openModal={toggleModal} />
      <Card isOpen={isModalOpen} toggleModal={toggleModal} />
    </div>
  );
}

export default App;
