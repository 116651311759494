import React, { useState, useEffect } from "react";
import "./HeroArea.css";
import heroImage from "../assets/image3.jpeg";

const HeroArea = () => {
  const [transformStyle, setTransformStyle] = useState("");

  // Function to handle mouse movement
  const handleMouseMove = (e) => {
    // Get the mouse position relative to the element
    const { clientX: mouseX, clientY: mouseY } = e;
    const { left, top, width, height } = e.target.getBoundingClientRect();

    // Calculate the position of the mouse inside the image
    const centerX = mouseX - left - width / 4;
    const centerY = mouseY - top - height / 4;

    // Adjust the values to create a more noticeable 3D effect
    const moveX = (centerX / width) * 3.14; // Max 30px on the X-axis
    const moveY = (centerY / height) * 2.71; // Max 30px on the Y-axis

    // Set the CSS transform with perspective and 3D translation
    setTransformStyle(
      `perspective(500px) rotateX(${-moveY}deg) rotateY(${moveX}deg)`
    );
  };

  // Optionally, reset the effect when the mouse leaves the image
  const handleMouseLeave = () => {
    setTransformStyle("perspective(500px) rotateX(0deg) rotateY(0deg)");
  };

  useEffect(() => {
    setTransformStyle("perspective(500px) rotateX(0deg) rotateY(0deg)");
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAdditionalInfo = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="hero-area">
      <div className="hero-content">
        <h1 className="hero-title">KAROLINA LANGE</h1>
        <p className="hero-subtitle">Franczyzobiorca McDonald's Polska.</p>
        <div className={`additional-info ${isExpanded ? "expanded" : ""}`}>
          <button className="toggle-button-hero" onClick={toggleAdditionalInfo}>
            {isExpanded ? "ukryj." : "puk, puk!"}
          </button>
          <div className="additional-content">
            <h3>Dane kontaktowe</h3>
            <ul>
              <li>NIP: 525-236-14-96</li>
              <li>
                📍 Wieżanka 1
                <br /> 17-207, Wieżanka
              </li>
              <li>📧 faktury@karolinalange.com</li>
              <li>📞 +48 606 694 998</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="hero-image"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={heroImage}
          alt="Hero section"
          className="image"
          style={{ transform: transformStyle }}
        />
      </div>
    </section>
  );
};

export default HeroArea;
