import React from "react";
import "./Footer.css";

const Footer = ({ openModal }) => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="brand-logo">K•L + dizajn.&copy;</div>
        <ul className="footer-menu">
          <li>
            <button onClick={openModal} className="footer-button">
              Dokumenty
            </button>
          </li>
          {/* <li>
            <div>&copy; 2024 dizajn.</div>
          </li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
