import React, { useState } from "react";
import "./InfoSection.css";
import restaurantImage from "../assets/image1.jpeg";

const InfoSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAdditionalInfo = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="info-section">
      <div className="restaurant-image-container">
        <div className="image-overlay">
          <img
            src={restaurantImage}
            alt="Restaurant"
            className="restaurant-image"
          />
          <div className="image-text">
            <h1>Ale, ale, Aleje!</h1>
          </div>
        </div>
      </div>
      <h2>Informacje o restauracji</h2>
      <div className="info-grid">
        <div className="paper">
          <h3>Adres</h3>
          <p>
            <a
              href="https://www.google.com/maps/place/Restauracja+McDonald's/@52.1884009,20.909504,18.91z/data=!3m1!5s0x47193495bdfc0953:0x4c97370c83335fbb!4m6!3m5!1s0x471935cb349175ff:0xfba3f4b4f1d38c9e!8m2!3d52.1878292!4d20.9103647!16s%2Fg%2F11jrchq56v?coh=164777&entry=tt&shorturl=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nawiguj na Big Mac!
            </a>
          </p>
          <p>Aleje Jerozolimskie 228, Warszawa</p>
        </div>
        <div className="paper">
          <h3>Telefon</h3>
          <p>
            <a href="tel:+1234567890">Zadzwoń</a>
          </p>
          <p>+1 (234) 567-890</p>
        </div>
        <div className="paper">
          <h3>Godziny Otwarcia</h3>
          <h4>Pon - Nd: </h4>
          <p>7:00 - 00:00 </p>
        </div>
      </div>
      <div className={`additional-info ${isExpanded ? "expanded" : ""}`}>
        <button className="toggle-button" onClick={toggleAdditionalInfo}>
          {isExpanded ? "mniej..." : "więcej?"}
        </button>
        <div className="additional-content">
          <h3>Dodatkowe informacje</h3>
          <ul>
            <li>🪑 - Ilość miejsc siedzących: Sala: 78 | Ogródek: 44.</li>
            <li>
              🅿️ – Miejsca parkingowe: 23 <br />
              ( W tym 1 dla niepełnosprawnych).
            </li>
            <li>🚲 – Stojak na rowery.</li>
            <li>🚻 – Toaleta dla niepełnosprawnych.</li>
            <li>
              🚘 – Możliwość podjechania samochodem i zamówienia, McDrive.
            </li>
            <li>
              🍽️ – Możliwość zamówienia do stolika lub miejsca parkingowego <br />
              ( 2 specjalnie oznaczone miejsca).
            </li>
            <li>📲 – Możliwość zamówienia przez Uber, Pyszne, Glovo i Wolt.</li>
            <li>📱- Możliwość złożenia zamówienia przez aplikację w telefonie lub kioski.</li>
            <li>🥚  & 🍔 – Oferta śniadaniowa i klasyczna.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
